let id = 0;

export const sidebarRoutes = [
  {
    id: ++id,
    path: "/",
    title: "Asosiy",
  },
  {
    id: ++id,
    path: "/courses",
    title: "Kurslar",
  },
  {
    id: ++id,
    path: "/#why",
    title: "Nimaga ITC?",
  },
];
